.login__img {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.login__img img {
  width: 70%;
  align-items: center;
  object-fit: contain;
}

.login__form {
  padding: 40px;
  background: var(--secondary-color);
  position: relative;
}

.login__container {
  margin-top: 2.5rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.login__form .user {
  width: 60px;
  height: 60px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.login__form .user img {
  width: 100%;
  object-fit: contain;
}

.login__form h2 {
  color: var(--heading-color);
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.login__form input {
  width: 100%;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  color: var(--heading-color);
  font-size: 1rem;
  border: none;
  outline: none;
}

.auth__btn {
  width: 100%;
  background: var(--heading-color) !important;
  color: #fff !important;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login__form p {
  color: #fff;
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
}

.login__form p a {
  color: var(--heading-color);
  text-decoration: none;
  margin-left: 5px;
  font-weight: 500;
}


@media only screen and (max-width: 768px) {
  .login__img {
    display: none;
  }

  .login__form {
    width: 70%;
    margin: auto;
    padding: 30px;
  }

  .login__container {
    padding: 2rem;
  }
}

@media only screen and (max-width: 576px) {
  .login__form {
    width: 100%;
    padding: 25px;
  }
  
  .login__container {
    padding-bottom: 1.5rem;
  }
  .login__form .user {
    width: 50px;
    height: 50px;
    top: -25px;
  }
}

@media only screen and (max-width: 376px) {
  .login__form {
    padding: 25px;
  }
  .login__form .user {
    width: 40px;
    height: 40px;
    top: -20px;
  }
}


