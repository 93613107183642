.title{
  font-size: 2.3rem;
  margin-bottom: .5rem;
  margin-top: 1rem;
}

.blog__content img {
  width: 60%;
  border-radius: 0.5rem;
  margin-bottom: 2.5rem;
}

.blog__info,
.blog__reviews {
  padding: 2rem;
  border: 1px solid rgb(229, 231, 235);
  border-radius: 0.5rem;
}

.blog__info h2 {
  font-size: 1.5rem;
}

.blog__info span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.1rem;
  color: var(--text-color);
}

.blog__info span i {
  color: var(--heading-color);
  font-size: 1.1rem;
}

.blog__extra-details {
  display: flex;
  align-items: center;
  column-gap: 1.7rem;
  margin-top: 1rem;
  margin-bottom: .5rem;
}

.blog__extra-details span {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.blog__extra-details p {
  line-height: 30px;
  font-size: 1rem;
  color: var(--text-color);
}

.rating__group span {
  font-size: 1.1rem;
  display: flex;
  align-items: center;
}

.rating__group span.active {
  color: var(--secondary-color);
}

.blog__reviews h4 {
  margin-bottom: 3rem;
}

.review__input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.7rem;
  border-radius: 2rem;
  border: 1px solid var(--secondary-color);
}

.review__input input {
  width: 100%;
  padding: 0.5rem 0;
  border: none;
  outline: none;
}

.review__item {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 2rem;
}

.review__item img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50px;
  object-fit: cover;
}

.user__reviews {
  margin-top: 2.5rem;
}

.review__item h5 {
  font-size: 1rem;
  margin-bottom: 0;
}

.review__item p {
  font-size: 0.9rem;
  color: var(--text-color);
}

.review__item h6 {
  font-size: 1.1rem;
  color: var(--text-color);
}

.review__item span {
  font-weight: 500;
}

.review__item span i {
  font-size: 1.2rem;
  color: var(--heading-color);
}

.Featured__blogs{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 2rem!important;
    border-radius: 0.5rem;
    border: 1px solid rgb(229, 231, 235);
    background-color:#fff;
    box-shadow: rgba(46, 57, 78,.1)!
}


@media only screen and (max-width: 992px) {

  .Featured__blogs{
    margin-top: 1rem;
  }

}
@media only screen and (max-width: 768px) {
  .blog__extra-details {
    flex-wrap: wrap;
    row-gap: 1rem;
  }

  .blog__extra-details span {
    font-size: 0.9rem;
  }

  .blog__extra-details span i {
    font-size: 1rem;
  }
    
.blog__content img {
  width: 80%;
  border-radius: 0.5rem;
  margin-bottom: 2.5rem;
}

.review__item img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50px;
  object-fit: cover;
}
  
}

@media only screen and (max-width: 576px) {
  .review__input {
    font-size: 0.8rem;
  }
  .blog__content img {
    width: 90%;
    border-radius: 0.5rem;
    margin-bottom: 2.5rem;
  }
}
