@import url("https://fonts.googleapis.com/css2?family=Island+Moments&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

:root {
  --primary-color: #ff7e01;
  --secondary-color: #faa935;
  --heading-color: #0b2727;
  --text-color: #6e7074;
  --font-name: "Montserrat", sans-serif;
  --subtitle-font-name: "Island Moments", cursive;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-name);
}

section {
  padding: 30px 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
}

a {
  text-decoration: none;
  color: unset;
}
ul {
  list-style: none;
}

.primary__btn {
  background: var(--secondary-color) !important;
  border: none !important;
  border-radius: 50px !important;
  padding: 0.4rem 1.5rem !important;
}

.primary__btn a {
  font-size: 1.1rem;
  text-decoration: none;
  color: #fff;
}
.primary__btn a:hover {
  color: inherit;
}
.secondary__btn {
  background: transparent !important;
  color: #0b2727 !important;
  border: none !important;
  font-weight: 500 !important;
}

.secondary__btn a {
  text-decoration: none;
  font-size: 1.1rem;
  color: var(--heading-color);
}

.secondary__btn a:hover {
  color: inherit;
}

.viall__btn {
  text-align: center;
}

.error__msg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  font-size: 20px;
  font-weight: bold;
}

.section__subtitle {
  background: var(--secondary-color);
  font-family: var(--subtitle-font-name);
  width: max-content;
  padding-right: 1rem;
  padding: 2px 15px;


  border-radius: 50px;
  font-weight: 500;
  font-size: 2rem;
  color: var(--heading-color);
}

/* spinner.css */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  font-size: 1.2rem;
  color: var(--text-color);
}

@media only screen and (max-width: 992px) {
  .primary__btn a,
  .secondary__btn a {
    font-size: 0.9rem;
  }

  .primary__btn,
  .secondary__btn,
  .btn-dark {
    padding: 0.2rem 1.1rem !important;
  }

  section {
    padding: 40px 0px;
  }
}

@media only screen and (max-width: 768px) {
  .section__subtitle {
    font-size: 1.7rem;
  }
}


/* src/TourPackageCard.css */
.container {
  max-width: 1200px; /* Adjust based on your design needs */
}
.card-container-1{
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap onto multiple lines */

  justify-content: space-around; /* Distributes space evenly between cards */
}
.tour-package-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  max-width: 300px;
  width: 290px;
  margin: 20px;
}

.tour-package-image {
   position: relative;
  overflow: hidden;
  width: 100%; /* Ensures the container takes full width */
  height: auto; /* Adjusts height based on content */
}

.tour-package-image img {
  transition: 0.5s ease; /* Smooth transition for the transform property */
  overflow: hidden;

}

.tour-package-image img:hover {
  transform: scale(1.1); /* Scales the image to 110% of its original size on hover */
  overflow: hidden;

}
.image-placeholder {
  width: 100%;
  height: 200px;
  background-color: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.tour-price {
  position: absolute;
  top: 10px;
  background-color: rgba(207, 89, 89, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
}

.tour-package-details {
  padding: 15px;
}

.tour-info {
  font-size: 14px;
  height: 30px;
  width: 200px;
  color: white;
  justify-content: center;
  align-items: center;
background-size: auto;
  background-color: #3d86b3;
}

.tour-title {
  font-size: 18px;
}

.tour-reviews {
  color: #ffa500;
}


.tour-description {
  font-size: 14px;
  color: #333;
  margin-bottom: 15px;
}

.tour-actions {
  display: flex;
  justify-content: space-between;
}

.book-now, .wish-list {
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.wish-list {
  background-color: #666;
}

.book-now:hover, .wish-list:hover {
  opacity: 0.8;
}
