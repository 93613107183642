.common__section {
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url("../assets/images/tour.jpg") no-repeat center center;
  background-size: cover;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.common__section h1{
    font-size: 2.5rem;
    color: #fff;
}